import React, { Component } from "react";
import "./style.scss";
import Header from "../../header";
import Layout from "../../layout";
import ProjectLayout from "../../projectlayout2";
import sliderImages from "./slider.data";
import SEO from "../../seo";

const details = {
  title: "Ratnadeep Institute of Medical Sciences",
  address: "Jamkhed, Maharashtra",
  client: "Ratanadeep Memorial Trust",
  area: "3,43,209 Sq. Ft.",
  project:
    "Institute of Homeopath, Ayurveda and Pharmacy for Ratnadeep Institute of Medical Sciences, Jamkhed, Maharashtra",
  category: "Institutions | Architecture | Institute",
  status: "Under Construction",
  backlink: "/projects/institutions/",
};

export default class Projectvpsssahmednagar extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="A&D Studio | Ratnadeep Institute of Medical Sciences"
          img="https://archdesignsstudio.com/assets/linkpreview.png"
          description="View site plans, architecture and design details for a homeopathy and ayurveda institute located in Maharashtra."
        />
        <Header isWhite />
        <ProjectLayout details={details} projectimages={sliderImages} />
      </Layout>
    );
  }
}
